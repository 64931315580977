<template>
    <section
        class="TextHero"
        :id="sliceId"
        :class="[
            'TextHero--' +
                (slice.primary.size || 'extra-large')
                    .toLowerCase()
                    .replace(' ', '-'),
            {
                'TextHero--ready': inView && wordsReady && !loadingScreenActive,
            },
        ]"
        ref="textHero"
        data-speed="clamp(1.5)"
    >
        <h1 class="TextHero__title" v-html="transformedTitle"></h1>
        <prismic-rich-text
            v-if="slice.primary.subtitle"
            :field="slice.primary.subtitle"
            class="TextHero__subtitle"
        />
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.TextHeroSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const transformedTitle = ref('')
const titleEndIndex = ref(0)
const endWordIndex = ref(0)
const textHero = ref()
const textHeroObserver = ref()
const inView = ref()
const wordsReady = ref(false)
const loadingScreenActive = ref(true)
const { $wordBuilder, hook } = useNuxtApp()
const sliceId = props.slice.id.replace('$', '-') + '-text-hero'

$wordBuilder(props.slice.primary.title).then((res) => {
    transformedTitle.value = res.words
    titleEndIndex.value = res.endIndex
    endWordIndex.value = res.endIndex
    wordsReady.value = true
})

hook('page:loading:end', () => {
    loadingScreenActive.value = false
})

onMounted(() => {
    textHeroObserver.value = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    inView.value = true
                    observer.disconnect()
                }
            })
        },
        {
            threshold: 1,
        },
    )

    textHeroObserver.value.observe(textHero.value)
})
</script>

<style lang="scss">
.TextHero {
    grid-column: 2 / span 22;
    margin-top: 7.5rem;
    margin-bottom: 6.5rem;

    @include large-up {
        margin-top: 12.75rem;
        margin-bottom: 8.75rem;
    }

    &:has(+ .ScrollerGallery) {
        @include small-only {
            margin-bottom: 3rem;
        }
    }

    &:has(+ .AccordionContentBlock) {
        margin-bottom: 3rem;
    }
}

.TextHero__title {
    @include heading-1;

    max-width: 70rem;
}

.TextHero__subtitle {
    @include body-lg;

    max-width: 44.375rem;
    margin-top: 1rem;
    transform: translateY(100%);
    opacity: 0;
    transition: all 1s 0.2s ease;

    @include medium-up {
        margin-top: 2rem;
    }
}

.TextHero__title {
    .word {
        overflow: hidden;
        display: inline-flex;
        line-height: 1;

        span {
            display: block;
            opacity: 1;
            transform: translate3d(0, 100%, 0);
            padding-right: 0.09em; // offset italic font edging out on the right
            --transition-delay: calc(0.25s + var(--index) * 0.08s);
        }
    }
}

/**

Variations

*/

.TextHero--large {
    .TextHero__title {
        @include heading-2;
    }

    .TextHero__subtitle {
        @include small-only {
            @include body-sm;
        }
    }

    .TextHero__title,
    .TextHero__subtitle {
        max-width: 45rem;

        @media (min-width: 1440px) {
            max-width: 60rem;
        }
    }
}

.TextHero--ready {
    opacity: 1;

    .word span {
        animation: text-reveal 1s forwards cubic-bezier(0.25, 1, 0.5, 1)
            var(--transition-delay);
    }

    .TextHero__subtitle {
        transform: translateY(0);
        opacity: 1;
    }
}
</style>
